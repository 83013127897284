import React, { useState, useMemo, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { graphql, Link, navigate } from 'gatsby';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Palette } from '@mui/icons-material';
import { theme } from '../theme';
import Layout from '../components/layout/layout';
import Markdown from '../components/markdown';
import ImageUploader from '../components/image-uploader';
import SelectionGroup from '../components/selection-group';
import { LoadingOverlay } from '../components/loading-overlay/loading-overlay';
import useFormGenerator from '../hooks/useFormGenerator';
import {
  useFileUploader,
  signUp,
  signIn,
  signOutFromFirebase,
} from '../hooks/useFirebase';
import {
  submitClaim,
  getClaimantData,
  submitClaimForHashing,
} from '../services/claims.service';
import { useSessionStorage } from '../hooks/useSessionStorage';
import useAuth from '../hooks/useAuthentication';
import { useClaimStatus, useSnackbar } from '../store';
import { SnackBar } from '../components/snackbar';
import { claimTypeValues } from '../constants';
import { parseFirebaseError } from '../utils/firebase-error-parser';
import { partTwoBlock, partTwoBlock2 } from '../../markdownSources';
import logAnalyticsEvent from '../utils/log-analytics-event';

const ClaimForm = ({ data }) => {
  useEffect(() => {
    logAnalyticsEvent('claim_form_page_visit', { caseId: data?.template?.caseID, })
  }, [])

  const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY;
  const {
    instructions,
    caseName,
    caseID,
    authentication,
    claimInfo,
  } = data?.template;
  const caseConfiguration = data?.caseConfiguration;

  const { openSnackbar } = useSnackbar();
  const { loadingAuthentication, authenticated } = useAuth();
  const { setClaimID } = useClaimStatus();
  const fileUploader = useFileUploader();
  const isLG = useMediaQuery(theme.breakpoints.up('lg'));

  const [files, setFiles] = useState([]);
  const [legalRepresentativeFiles, setLegalRepresentativeFiles] = useState([]);
  const [captcha, setCaptcha] = useState();
  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');

  const submitUndefinedPurchaserClaim = async (request, headers) => {
    const {
      data: { orderID, key },
    } = await submitClaimForHashing({ job: request }, headers);
    await signUp(`${orderID}${caseID}@claimscore.ai`, key);
    await submitClaim({ job: request, orderID, key });
    return { data: { orderID } };
  };

  const [
    partOneGenerator,
    submitHandler,
    updateInitialValues,
    partTwoGenerator,
    partThreeGenerator,
  ] = useFormGenerator({
    subscriptionData: {
      subscriptionType,
      subscriptionStatus,
      subscriptionStatusAndType: `${subscriptionStatus} ${subscriptionType}`,
    },
    onSubmit: async (values, actions) => {
      try {
        setLoading(true);

        if (values.notificationType === 'pop' && files.length === 0) {
          openSnackbar('Proof of purchase files are required', 'error');
          setLoading(false);
          return;
        }
        const fileUploads = files.map((file) => {
          const fileUrl = `/pop/pending/${caseID}/${uuid()}.${file.name.split('.')[1]
            }`;
          return fileUploader(fileUrl, file).then(
            (snapshot) => snapshot.metadata.fullPath
          );
        });

        if (
          values.claimantIdentity !== 'individual' &&
          legalRepresentativeFiles.length === 0
        ) {
          openSnackbar('Legal representation files are required', 'error');
          setLoading(false);
          return;
        }

        const LegalRepresentativeUploads = legalRepresentativeFiles.map(
          (file) => {
            const fileUrl = `/legal/${caseID}/${uuid()}.${file.name.split('.')[1]
              }`;
            return fileUploader(fileUrl, file).then(
              (snapshot) => snapshot.metadata.fullPath
            );
          }
        );

        let popFileUrls;
        let representationFileUrls;

        try {
          popFileUrls = await Promise.all(fileUploads);
          representationFileUrls = await Promise.all(
            LegalRepresentativeUploads
          );
        } catch (error) {
          openSnackbar(
            'There was an error uploading the files.  Please try reloading the page.',
            'error'
          );
          setLoading(false);
          return;
        }

        const claimantMustIdentify = values.notificationType === 'direct';

        const { directNotice, pop, nonPop } = claimTypeValues;

        const request = {
          caseID,
          caseName,
          claimSearch: {
            orderDate: new Date().toISOString(),
            billingFirstName: values.firstName,
            billingLastName: values.lastName,
            billingStreet: values.address,
            billingStreet2: values.address2,
            billingCity: values.city,
            billingState: values.state,
            billingZip: values.zipCode,
            phone: values.phone,
            email: values.email,
            claimItemType: claimantMustIdentify
              ? directNotice
              : files[0]
                ? pop
                : nonPop,
            popLinks: popFileUrls,
            referrer: document.referrer,
            productData: {
              date: values.purchaseDate
                ? dayjs(values.purchaseDate).format('MM/DD/YYYY')
                : '',
              location: values.purchaseLocation,
            },
            additionalData: {
              acceptedPrivacy: values.acceptedPrivacy,
              todayDate: values.todayDate
                ? dayjs(values.todayDate).format('MM/DD/YYYY')
                : '',
              date: dayjs(values.date).format('MM/DD/YYYY'),
              phone2: values.phone2,
              claimantIdentity: values.claimantIdentity,
              claimantFullName: values.claimantFullName,
              legalRepresentativeName: values.legalRepresentativeName,
              legalRepresentativeCapacity: values.legalRepresentativeCapacity,
              legalRepresentativeUploads: representationFileUrls,
              certified: values.certified,
            },
          },
        };

        const headers = {
          'x-recaptcha-token': captcha,
        };

        if (claimantMustIdentify) {
          if (!values.pin || !values.uniqueId) {
            return;
          }
          try {
            await signIn(
              `${values.uniqueId}${caseID}@claimscore.ai`,
              values.pin
            );
          } catch (err) {
            actions.setErrors(parseFirebaseError(authentication));
            setLoading(false);
            return;
          }
        }

        let data;
        if (claimantMustIdentify) {
          data = await submitClaim({
            job: request,
            pin: values.pin,
            uniqueId: values.uniqueId,
          });
        } else {
          data = await submitUndefinedPurchaserClaim(request, headers);
        }
        logAnalyticsEvent('claim_form_submit_success', { claimId: orderID, caseId: caseID });
        const { orderID } = data;
        setClaimID(orderID);
        navigate('/claim-check');
        // TODO: check that orderID is returned and captcha is sent
        return;
      } catch (e) {
        logAnalyticsEvent('claim_form_submit_error', { caseId: caseID });
        const errorMessage =
          'Experiencing an unexpected error. Please try reloading the page.';
        openSnackbar(errorMessage, 'error');
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const getClaimant = async () => {
      try {
        setLoading(true);

        const result = await getClaimantData(caseID);
        const metadata = JSON.parse(result.data.claimant.metadata);
        setSubscriptionStatus(metadata.subscriptionStatus);
        setSubscriptionType(metadata.subscriptionType);

        updateInitialValues(result.data.claimant);
      } catch (err) {
        openSnackbar(
          'Experiencing an unexpected error. Please try reloading the page.',
          'error'
        );
      } finally {
        setLoading(false);
      }
    };

    if (authenticated && authentication?.loginForm) {
      getClaimant();
    }
  }, [authenticated]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <LoadingOverlay open={loading} />
      <Layout>
        <SnackBar>
          <form onSubmit={submitHandler}>
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: 700, fontSize: '48px' }}>
                Submit a Claim
              </Typography>
            </Box>
            <Box sx={{ mb: 8 }}>
              <Breadcrumbs separator="→" aria-label="breadcrumb">
                {[
                  <Link key="1" to="/">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: '16px',
                          md: '32px',
                          color: 'neutral.500',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Home
                    </Typography>
                  </Link>,
                  Array.isArray(data.template.instructions) &&
                  data.template.instructions.length > 0 && (
                    <Link key="2" to="/claim-instructions">
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '16px',
                            md: '32px',
                            color: 'neutral.500',
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        Claim Instructions
                      </Typography>
                    </Link>
                  ),
                  <Typography
                    key="3"
                    sx={{ fontSize: { xs: '16px', md: '32px' } }}
                  >
                    Claim Form
                  </Typography>,
                ]}
              </Breadcrumbs>
            </Box>

            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  Part One: Claimant Information
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>{partOneGenerator()}</Grid>
              </CardContent>
            </Card>

            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  Part Two: Subscription Information
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>
                  <Grid item>
                    <p>
                      <Typography variant="body">
                        To quality for a settlement benefit, you must have been
                        a FloSports subscriber who, from August 29, 2018 through
                        [DATE]. enrolled in an automatically renewing FloSports
                        subscription using a California, New York, North
                        Carolina, Oregon, Florida, Illinois, Washington D.C.,
                        North Dakota, Virginia, Hawaii, Vermont billing address
                        and paid fee(s) in connection with your subscription.
                        Your subscription type (Monthly or Annual) is listed
                        below; if you believe this is inaccurate, contact the
                        &nbsp;
                      </Typography>
                      <strong
                        style={{
                          color: '#3E8682',
                          textDecoration: 'underline',
                        }}
                      >
                        Settlement Administrator.
                      </strong>
                    </p>
                  </Grid>
                  {partTwoGenerator()}
                  <Grid item sx={{ mb: 3 }}>
                    <Typography variant="h6">
                      The cash payments set out herein represent the maximum
                      that you can receive under the settlement. The actual cash
                      paid may be reduced depending on the aggregate total of
                      claims submitted by all class members.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Markdown source={partTwoBlock2} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ mb: 4 }}>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                  Part Three: Attestation under penalty of perjury.
                </Typography>
              </CardContent>
              <Divider />
              <CardContent>
                <Grid container>{partThreeGenerator()}</Grid>
              </CardContent>
            </Card>
            {RECAPTCHA_KEY && (
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={(value) => {
                  setCaptcha(value);
                }}
              />
            )}
            <Box sx={{ mb: 2 }}>
              <Button
                type="submit"
                fullWidth={!isLG}
                variant="contained"
                sx={{
                  my: 2,
                  color: 'background.paper',
                  px: { md: '44px' },
                  py: 2,
                  fontSize: '15px',
                  fontWeight: 400,
                }}
                disableElevation
              >
                Submit Claim
              </Button>
            </Box>
          </form>
        </SnackBar>
      </Layout>
    </LocalizationProvider>
  );
};

export default ClaimForm;

export const Head = ({ data }) => (
  <title>ClaimForm | {data.template.caseName}</title>
);

export const query = graphql`
  query FormPage($id: StringQueryOperatorInput = {}) {
    caseConfiguration(id: $id) {
      billingCity {
        editable
        nameToShow
      }
      billingFirstName {
        editable
        nameToShow
      }
      billingLastName {
        editable
        nameToShow
      }
      billingState {
        editable
        nameToShow
      }
      billingStreet {
        editable
        nameToShow
      }
      billingStreet2 {
        editable
        nameToShow
      }
      billingZip {
        editable
        nameToShow
      }
      email {
        editable
        nameToShow
      }
      phone {
        editable
        nameToShow
      }
    }
    template(id: $id) {
      caseID
      caseName
      claimInfo {
        type
        wrapper
        inputs
        text
        group
        options {
          id
          description
          value
        }
      }
      instructions {
        type
      }
      authentication {
        loginForm
        idFieldName
        secretFieldName
      }
    }
  }
`;
