import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { DateField } from '@mui/x-date-pickers/DateField';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Checkbox,
  Stack,
  FormHelperText,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'gatsby';
import { country_list } from '../constants';
import PlacesAutocomplete from '../components/places-autocomplete';
import ImageUploader from '../components/image-uploader';

export default function useFormGenerator({
  subscriptionData,
  inputList,
  includeUniqueID,
  caseConfiguration,
  errorEmail,
  loginForm,
  authentication,
  onSubmit,
}) {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      email: '',

      // additionalData
      // notificationType: '',
      // notification: 'direct'
      uniqueId: '',
      pin: '',

      // notification: 'pop'
      // receipt: '',

      // notification: 'none'
      // purchaseDate: '',
      // purchaseLocation: '',

      date: '',
      claimantFullName: '',

      // claimantIdentity: !individual
      certified: false,

      acceptPrivacy: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().max(255).required('First Name is required'),
      lastName: Yup.string().max(255).required('Last Name is required'),
      address: Yup.string().max(255).required('Address is required'),
      address2: Yup.string().max(255),
      city: Yup.string().max(255).required('City is required'),
      state: Yup.string()
        .max(
          2,
          'State must be two characters long. Please use its abbreviated form.'
        )
        .required('State is required'),
      zipCode: Yup.string().max(255).required('Zip Code is required'),
      email: Yup.string().max(255).email().required('Email is required'),
      // notificationType: Yup.string()
      //   .oneOf(['direct', 'pop', 'none'])
      //   .required(),
      // uniqueId: Yup.string().when(['notificationType'], {
      //   is: (type) => type === 'direct',
      //   then: Yup.string()
      //     .min(8, 'Unique ID is a 8 digit code')
      //     .max(8, 'Unique ID is a 8 digit code')
      //     .required('Please add your Unique ID'),
      // }),
      // pin: Yup.string().when(['notificationType'], {
      //   is: (type) => type === 'direct',
      //   then: Yup.string()
      //     .min(6, 'PIN is at least 6 characters long')
      //     .required('Please add your PIN'),
      // }),
      date: Yup.string().required(`Today's date is required`),
      claimantIdentity: Yup.string().required('Please select one of the above options'),
      claimantFullName: Yup.string()
        .max(255)
        .required(`Claimant's name is required`),
      certified: Yup.boolean().isTrue('Certification is required'),
      acceptPrivacy: Yup.boolean().isTrue('Must accept our privacy policy'),
    }),
    onSubmit,
  });

  const updateInitialValues = (_claimant) => {
    formik.setValues({
      firstName: _claimant?.billingFirstName,
      middleInitial: _claimant?.middleInitial,
      lastName: _claimant?.billingLastName,
      country: _claimant?.country,
      address: _claimant?.billingStreet,
      address2: _claimant?.billingStreet2,
      city: _claimant?.billingCity,
      state: _claimant?.billingState,
      zipCode: _claimant?.billingZip,
      email: _claimant?.email,
      certified: _claimant?.certified,
    });
  };

  const partOneGenerator = useCallback(
    () => (
      <React.Fragment>
        <Grid item xs={12} sm={6} lg={6}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label={'First Name'}
            name="firstName"
            variant="outlined"
            placeholder="John"
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.firstName && formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label={'Last Name'}
            name="lastName"
            variant="outlined"
            placeholder="Doe"
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.lastName && formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label={'Street Address 1'}
            name="address"
            variant="outlined"
            value={formik.values.address}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.address && formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label={'Street Address 2'}
            name="address2"
            variant="outlined"
            value={formik.values.address2}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.address2 && formik.errors.address2)}
            helperText={formik.touched.address2 && formik.errors.address2}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label={'City'}
            name="city"
            variant="outlined"
            placeholder="Red Bank"
            value={formik.values.city}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.city && formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label={'State'}
            name="state"
            variant="outlined"
            placeholder="New Jersey"
            value={formik.values.state}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.state && formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label={'Zip Code'}
            name="zipCode"
            variant="outlined"
            placeholder="XXXXX or xxxxx-xxxx"
            value={formik.values.zipCode}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.zipCode && formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            sx={{ mb: 3 }}
            label="Email"
            name="email"
            variant="outlined"
            placeholder="johndoe@email.com"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
      </React.Fragment>
    ),
    [formik]
  );

  const partTwoGenerator = useCallback(
    () => (
      <React.Fragment>
        <Grid item xs={12} sx={{ my: 3 }}>
          <Box display="flex">
            <Typography variant="h6">
              According to our records you are an {subscriptionData.subscriptionStatusAndType?.toLowerCase()} subscriber to
              FloSports,&nbsp;
            </Typography>
            <Typography variant="body">
              select one of the following options:
            </Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="identity-radio-group-label"
                name="claimantIdentity"
                value={formik.values.claimantIdentity}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="individual"
                  control={
                    <Radio
                      checked={formik.values.claimantIdentity === 'individual'}
                      sx={{
                        color:
                          !formik.values.claimantIdentity && formik.errors.claimantIdentity
                            ? 'error.main'
                            : 'unset',
                      }}
                    />
                  }
                  label={
                    <Typography variant="body" sx={{ fontWeight: 400 }}>
                      <span style={{ fontWeight: 600, fontSize: '16px' }}>
                        Option 1:{' '}
                      </span>
                      You'd like to receive a cash payment. {subscriptionData.subscriptionType} subscribers
                      are eligible to receive up to ${subscriptionData.subscriptionType === 'Annual' ? '30' : '6'}.00.
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="other"
                  control={
                    <Radio
                      checked={formik.values.claimantIdentity === 'other'}
                      sx={{
                        color:
                          !formik.values.claimantIdentity && formik.errors.claimantIdentity
                            ? 'error.main'
                            : 'unset',
                      }}
                    />
                  }
                  label={
                    <Typography variant="body">
                      <span style={{ fontWeight: 600, fontSize: '16px' }}>
                        Option 2:{' '}
                      </span>
                      {
                        subscriptionData.subscriptionStatus === 'Active'
                          ? (
                            <Typography variant="body">
                              You'd like to receive a 10% discount on your next
                              immediate renewal charge (or an electronic voucher for a
                              free pay-per-view event from FloSports, with certain
                              exceptions,<sup>1</sup> if your membership is no longer active when
                              the settlement becomes final)
                            </Typography>
                          )
                          : (
                            <Typography variant="body">
                              You'd like to receive an electronic voucher for one
                              free pay-per-view event from FloSports, with certain
                              exceptions.<sup>1</sup>
                            </Typography>
                          )
                      }
                      
                    </Typography>
                  }
                />
              </RadioGroup>
              {Boolean(!formik.values.claimantIdentity && formik.errors.claimantIdentity) && (
                <FormHelperText
                  sx={{
                    color:
                      !formik.values.claimantIdentity && formik.errors.claimantIdentity
                        ? 'error.main'
                        : 'unset',
                  }}
                >
                  {!formik.values.claimantIdentity && formik.errors.claimantIdentity}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>
      </React.Fragment>
    ),
    [formik]
  );

  const partThreeGenerator = useCallback(
    () => (
      <React.Fragment>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <FormControl
            error={Boolean(formik.touched.certified && formik.errors.certified)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="certified"
                  checked={formik.values.certified}
                  onChange={formik.handleChange}
                  sx={{
                    color:
                      formik.touched.certified && formik.errors.certified
                        ? 'error.main'
                        : 'unset',
                  }}
                />
              }
              label={
                <p>
                  <strong>
                    I declare under penalty of perjury under the laws of the
                    State of Illinois and the United States of America that all
                    of the information on this Claim form is true and correct to
                    the best of my knowledge. I understand that my Claim Form
                    may be subject to audit, verification, and Court review.
                  </strong>
                </p>
              }
            />
            {Boolean(formik.touched.certified && formik.errors.certified) && (
              <FormHelperText>
                {formik.touched.certified && formik.errors.certified}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label={'Claimant Full Name'}
            name="claimantFullName"
            variant="outlined"
            placeholder="Robert"
            value={formik.values.claimantFullName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={Boolean(
              formik.touched.claimantFullName && formik.errors.claimantFullName
            )}
            helperText={
              formik.touched.claimantFullName && formik.errors.claimantFullName
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6} sx={{ mt: 3 }}>
          <DateField
            fullWidth
            sx={{ mb: 3, pr: { xs: 0, sm: 3 } }}
            label={"Today's Date"}
            name="date"
            variant="outlined"
            placeholder="mm/dd/yyyy"
            value={formik.values.date}
            onBlur={formik.handleBlur}
            format="MM/DD/YYYY"
            onChange={(value) => {
              formik.setFieldValue('date', value);
            }}
            error={Boolean(formik.touched.date && formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider fullWidth />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                name="acceptPrivacy"
                checked={formik.values.acceptPrivacy}
                onChange={formik.handleChange}
                sx={{
                  color:
                    formik.touched.acceptPrivacy && formik.errors.acceptPrivacy
                      ? 'error.main'
                      : 'unset',
                }}
              />
            }
            label={
              <Typography>
                By clicking “Submit Claim” you are confirming that you have read
                and agree to our <Link to="/privacy">Privacy Policy</Link>
              </Typography>
            }
          />
          {Boolean(formik.touched.acceptPrivacy && formik.errors.acceptPrivacy) && (
            <FormHelperText
              sx={{
                color:
                  formik.touched.acceptPrivacy && formik.errors.acceptPrivacy
                    ? 'error.main'
                    : 'unset',
              }}
            >
              {formik.touched.acceptPrivacy && formik.errors.acceptPrivacy}
            </FormHelperText>
          )}
        </Grid>
      </React.Fragment>
    ),
    [formik]
  );

  return [
    partOneGenerator,
    formik.handleSubmit,
    updateInitialValues,
    partTwoGenerator,
    partThreeGenerator,
  ];
}
