type AuthenticationPayload = {
  loginForm: string;
  idFieldName: string;
  secretFieldName: string;
};

export const parseFirebaseError = (
  authentication: AuthenticationPayload
): { pin: string; uniqueId: string } => {
  const pinLabel = authentication.secretFieldName || 'PIN';
  const uniqueIdLabel = authentication.idFieldName || 'Unique ID';
  return {
    pin: `Invalid ${uniqueIdLabel} or ${pinLabel}`,
    uniqueId: `Invalid ${uniqueIdLabel} or ${pinLabel}`,
  };
};
